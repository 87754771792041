* {
  box-sizing: border-box;
  max-width: 100%;
}

body {
  position: relative;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  background: #FAF9F6; 
  background-size: cover;
  font-family: 'Old Standard TT', serif;  
  background-position: top;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  opacity: 0.95;
  background-attachment: fixed;
}

.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.big{
  font-size:20px;
  font-weight:600;
}
.form-container {
  position: relative;
  z-index: 1;
  width: 90%; /* Adjusted for mobile */
  padding: 10px; /* Adjusted for mobile */
  margin: 5%; /* Margin for mobile */
  box-sizing: border-box;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

@media (min-width: 768px) {
  .form-container {
    width: 50vw; /* adjusted from 60% to 60vw */
    padding: 20px;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .form-container {
    width: 40vw; /* adjusted from 50vh to 50vw */
    padding: 20px;
    margin: 0;
  }
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.button-add, .button-submit {
  background-color: lightblue;
}

.button-submit {
  background-color: lightgreen;
}

.button-remove {
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  line-height: 1; 
  margin: 0px;
  margin-left: 5px;
}

.button-remove:before {
  padding-right: 5px;
}

.guest-input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.guest-input-wrapper input {
  flex: 1;
  margin-right: 10px;
}

.guest-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.switch-label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 26px;
  font-family: 'French Script MT', sans-serif;
  justify-content: center;
}

h1 {
  font-family: 'French Script MT', sans-serif;
  font-size: 40px;
}

h2 {
  font-family: 'French Script MT', sans-serif;
  font-size: 24px;
}

p {
  font-family: 'Old Standard TT', serif;
  font-size: 18px;
}

form, .thank-you {
  text-align: center;
  padding: 20px;
  width: 100%; /* adjusted from 50vh to 90% */
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.7);
}

input {
  display: block;
  width: 97%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: 'Old Standard TT', serif;
  font-size: 20px;
}

.guest-input-container input {
  width: 75%;
  display: inline-block;
  margin-bottom: 0px;
}

button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: 'Old Standard TT', serif;
  font-size: 20px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media only screen and (max-width: 600px) {
  form, .thank-you {
    padding: 15px;
    width: 90vw;
  }

  input {
    width: 92%;
  }

  .guest-input-container input {
    width: 70%;
  }
}

.link {
  padding: 0px;
  margin: 0px;
  line-height: 20px;
}

a {
  color: lightblue;
  text-decoration: none;
}
